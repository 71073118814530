import { render, staticRenderFns } from "./Donate.vue?vue&type=template&id=db50384e&scoped=true"
import script from "./Donate.vue?vue&type=script&lang=js"
export * from "./Donate.vue?vue&type=script&lang=js"
import style0 from "./Donate.vue?vue&type=style&index=0&id=db50384e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db50384e",
  null
  
)

export default component.exports