<template>
  <div class="mb-8">
    <banner title="Donation Page" />
    <v-container class="container">
      <div v-if="loading" class="spinner"></div>
      <iframe
        @load="onLoad"
        v-show="!loading"
        :src="iframesrc"
        frameborder="0"
        scrolling="no"
        seamless="seamless"
        class="my-auto mx-auto"
        title="Graceway Radio Donation Form"
      ></iframe>
    </v-container>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";

export default {
  name: "Donate",
  components: {
    Banner,
  },
  data: () => ({
    loading: true,
    iframesrc: "https://secure.qgiv.com/for/gracewayradio/embed/71290/",
  }),
  methods: {
    onLoad: function () {
      this.loading = false;
    },
  },
  metaInfo() {
    return {
      title: "Donation Page",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Please prayerfully consider donating to GraceWay Radio - our station is completely faith-based and listener-supported.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Please prayerfully consider donating to GraceWay Radio - our station is completely faith-based and listener-supported.",
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Donation Page" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/donate",
        },
      ],
    };
  },
};
</script>

<style scoped>
iframe {
  height: 1100px;
  width: 100%;
}
.spinner {
  height: 520px;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-image: url("../assets/loader.gif");
  background-repeat: no-repeat;
  background-position: center center;
}
</style>

<!-- <template>
  <div>
    <banner title="Donation Page" />
    <v-container class="mb-15">
      <v-row justify="center">
        <v-col lg="10">
          <v-tabs v-model="tab" grow background-color="#1E91FF" dark slider-color="yellow" slider-size="4"
            active-class="active_tab" center-active>
            <v-tab v-for="c in choices" :key="c.name">
              {{ c.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-tabs-items v-model="tab" class="mt-4">
        <v-tab-item v-for="c in choices" :key="c.name">
          <v-row justify="center">
            <v-col align="center" sm="7">
              <v-card flat>
                <v-list>
                  <div class="text-overline text-md-h6 text-lg-h5">Donation Options - Please Select One</div>
                  <v-divider class="ma-0" inset></v-divider>
                  <v-list-item-group>
                    <v-list-item v-for="item in c.options" :key="item.price" :active="isSelected(item.price)"
                      @click="toggleSelected(item)" active-class="item-active">
                      <v-list-item-icon>
                        <v-icon class="mr-2" v-if="item.mode === 'payment'">mdi-credit-card-check-outline</v-icon>
                        <v-icon v-else class="mr-2">mdi-credit-card-clock-outline</v-icon>
                        <v-list-item-content>
                          <v-list-item-title :style="$vuetify.breakpoint.smAndDown ? '' : 'font-size:1.4em;'">
                            ${{ item.value }} {{ item.frequency }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item-icon>
                      <v-spacer></v-spacer>
                      <v-icon v-if="isSelected(item.price)" color="green" large>mdi-check-outline</v-icon>
                    </v-list-item>
                  </v-list-item-group>
                  <v-divider />
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <stripe-checkout billing-address-collection="required" ref="checkoutRef" :mode="cheeseburger" :pk="publishableKey"
        :line-items="selected" :success-url="successURL" :cancel-url="cancelURL" @loading="(v) => (loading = v)"
        locale="donate" submit-type="donate" />
      <v-row class="justify-center align-center mt-n3 mb-3">
        <v-col sm="7" lg="5" md="5">
          <v-btn class="mt-4" block :disabled="!selectedLineItem" @click="submit" :dark="!!selectedLineItem"
            color="blue">Donate</v-btn>
        </v-col>
      </v-row>

      <v-img src="https://gracewayradio.com/artwork/stripe.png" max-width="350px"
        style="margin-left:50%; transform:translate(-50%);" class="mb-4" alt="GraceWay Donation"></v-img>
      <p class="text-center text-weak mb-0 text-subtitle-2">Donations are tax deductible.</p>
      <p class="text-center text-weak mt-0 text-subtitle-2">You will be redirected after the transaction.</p>
    </v-container>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import Banner from '../components/Banner.vue';

export default {
  name: 'Donate',
  components: {
    StripeCheckout,
    Banner,
  },
  metaInfo() {
    return {
      title: "Donation Page",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Please prayerfully consider donating to GraceWay Radio - our station is completely faith-based and listener-supported."
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Please prayerfully consider donating to GraceWay Radio - our station is completely faith-based and listener-supported."
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Donation Page" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/donate"
        }
      ]
    };
  },
  data: () => ({
    publishableKey: 'pk_live_rgO4Hlt3GXvvxcZ2K3k8THzV',
    tab: null,
    selected: [],
    selectedLineItem: null,
    loading: false,
    max: 1,
    cheeseburger: 'payment',
    choices: [
      {
        name: 'One Time',
        options: [
          {
            price: 'price_1JWCyDDgTT4Ywrr9uGxEumiY',
            value: 5,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment',
          },
          {
            price: 'price_1HzWmIDgTT4Ywrr9BnTKEU0k',
            value: 10,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment',
          },
          {
            price: 'price_1HzWmIDgTT4Ywrr98bJehBdg',
            value: 20,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment',
          },
          {
            price: 'price_1HzWmIDgTT4Ywrr9MPhFJ2Nj',
            value: 50,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment',
          },
          {
            price: 'price_1IBijzDgTT4Ywrr967oDZz9A',
            value: 100,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment',
          },
          {
            price: 'price_1MN3rkDgTT4Ywrr9XmYxM61A',
            value: 250,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment'
          },
          {
            price: 'price_1MN3rkDgTT4Ywrr98GGzDrOX',
            value: 500,
            quantity: 1,
            frequency: 'One-Time',
            mode: 'payment'
          },
        ],
      },
      {
        name: 'Recurring',
        options: [
          {
            price: 'price_1JWD0kDgTT4Ywrr9eXkYPAyT',
            value: 5,
            quantity: 1,
            frequency: 'Monthly',
            mode: 'subscription',
          },
          {
            price: 'price_1HzWnbDgTT4Ywrr9pIpLyrtP',
            value: 10,
            quantity: 1,
            frequency: 'Monthly',
            mode: 'subscription',
          },
          {
            price: 'price_1ILf5WDgTT4Ywrr9k2aFYQSa',
            value: 20,
            quantity: 1,
            frequency: 'Monthly',
            mode: 'subscription',
          },
          {
            price: 'price_1ILf5WDgTT4Ywrr99bzeB3Yl',
            value: 50,
            quantity: 1,
            frequency: 'Monthly',
            mode: 'subscription',
          },
          {
            price: 'price_1ILf5WDgTT4Ywrr9k3eO1tmv',
            value: 100,
            quantity: 1,
            frequency: 'Monthly',
            mode: 'subscription',
          },
          {
            price: 'price_1ILf7iDgTT4Ywrr9SqOfGVyG',
            value: 100,
            quantity: 1,
            frequency: 'Yearly',
            mode: 'subscription',
          },
          {
            price: 'price_1MN54BDgTT4Ywrr9HE8vitmd',
            value: 250,
            quantity: 1,
            frequency: 'Yearly',
            mode: 'subscription'
          },
          {
            price: 'price_1MN55JDgTT4Ywrr9sS0yQOLC',
            value: 500,
            quantity: 1,
            frequency: 'Yearly',
            mode: 'subscription'
          },
          {
            price: 'price_1MN56iDgTT4Ywrr9NbmEDGLT',
            value: 1000,
            quantity: 1,
            frequency: 'Yearly',
            mode: 'subscription',
          }
        ],
      },
    ],

    successURL: 'https://www.gracewayradio.com/success',
    cancelURL: 'https://www.gracewayradio.com/cancel',
  }),
  methods: {
    toggleSelected(lineItemObject) {
      if (this.selectedLineItem?.price === lineItemObject.price) {
        this.selectedLineItem = null;
        this.selected = [];
      } else {
        this.selectedLineItem = lineItemObject;
        this.cheeseburger = this.selectedLineItem.mode;
        this.selected = [];
        this.selected.push({
          price: lineItemObject.price,
          quantity: lineItemObject.quantity,
        });
      }
    },
    isSelected(priceHash) {
      return (
        !!this.selectedLineItem && this.selectedLineItem.price === priceHash
      );
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
  computed: {
    deSelectCheckbox() {
      if (
        // eslint-disable-next-line operator-linebreak
        this.selected.length >= this.max &&
        this.selected.includes(this.lineItems.price)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.active_tab {
  font-size: 1.1em;
  transition: all 0.2s linear;

  &:active {
    transform: scale(1.2);
  }
}

.item-active {
  background-color: rgba(61, 156, 234, 0.5);
  border-left: 4px solid dodgerblue;
  color: white;
  font-size: 1.3rem;
}

.v-list-item:hover:not(v-list-item--active) {
  border-left: 4px solid darkblue;
  background-clip: padding-box;
  background-color: rgba(144, 202, 249, 0.5);
}
</style> -->
